import { makeAutoObservable } from 'mobx'
import { FetchStages } from '../../types/Fetch.types'
import { IProject } from './Project.types'
import { ProjectsData } from '../OurProjectsData'

class ProjectStore {
  data: IProject | null = null

  isProjectFetch = FetchStages.waiting
  get = async ({ projectId }: { projectId?: string }) => {
    try {
      if (!projectId) {
        throw new Error('Проект не найден')
      }
      if (this.isProjectFetch === FetchStages.start) return
      this.isProjectFetch = FetchStages.start
      const data: IProject = await this.fetchProjectData({ projectId })
      this.data = data
      this.isProjectFetch = FetchStages.end
    } catch (error) {
      this.isProjectFetch = FetchStages.error
    }
  }

  loadImage = async (imagePath: string) => {
    try {
      const image = await import(`${imagePath}`)
      return image.default
    } catch (error) {
      console.error('Error loading image:', error)
      return null
    }
  }

  fetchProjectData = async ({
    projectId,
  }: {
    projectId: string
  }): Promise<IProject> => {
    return new Promise((resolve, reject) => {
      const project = ProjectsData[projectId]
      if (project) {
        resolve(project)
      } else {
        reject(new Error('Project not found'))
      }
    })
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export default new ProjectStore()