import PortfolioProject from "../ui/PortfolioProject";
import { ProjectsData } from "../../store/PortfolioProjectData";

const PortfolioList = () => {
  return (
    <>
      <section className="pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] dark:bg-dark">
        <div className="container mx-auto px-32">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center">
                <span className="text-primary mb-2 block text-lg font-semibold">
                  Наше портфолио
                </span>
                <h2 className="text-dark mb-3 text-3xl leading-[1.208] font-bold sm:text-4xl md:text-[40px]">
                  Актуальные проекты
                </h2>
                <h6 className="text-body-color text-base dark:text-dark-6">
                  Мы работаем в различных областях, включая
                  веб-разработку, мобильные приложения,
                  искусственный интеллект, автоматизацию
                  процессов и многое другое. Наша команда
                  талантливых специалистов создает
                  инновационные и полезные продукты,
                  преодолевая любые вызовы и достигая
                  впечатляющих результатов. Мы гордимся
                  каждым проектом, которым мы занимаемся, и
                  стремимся к превосходству в качестве и
                  удовлетворенности клиентов.
                </h6>
              </div>
            </div>
          </div>

          <div className="-mx-4 flex flex-wrap">
            {ProjectsData.map((project, index) => (
              <PortfolioProject
                key={project.id}
                project={project}
                aos={index % 3 === 0 ? "fade-left" : index % 3 === 1 ? "fade-up" : "fade-right"}
              />
            ))}
            
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioList;
