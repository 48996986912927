import React from 'react'
import { Button, Result } from 'antd'

const My500Component: React.FC = () => (
  <Result
    status="500"
    title="500"
    subTitle="Извините, ошибка на сервере"
    extra={
      <Button className="bg-blue-600" type="primary">
        Домой
      </Button>
    }
  />
)

export default My500Component
