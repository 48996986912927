import { FC } from "react";
import { NavLink } from "react-router-dom";
import Logotype from "./Logotype";
import phoneImg from "../../assets/phone.svg";
import CallNow from "./Modals/CallNow";
import useModalForm from "../../hooks/useModal";
import ApplicationAcceptedModal from "./Modals/ApplicationAcceptedModal";

const Header: FC = () => {
  const {
    isModalOpen,
    formSubmitted,
    handleOpenModal,
    handleCloseModal,
    handleFormSubmitSuccess,
  } = useModalForm("get_call");

  return (
    <header className="text-gray-600 body-font sticky top-0 z-30 bg-white">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <NavLink
          to={'/'}
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
        >
          <Logotype />
          <span className="ml-3 text-xl">DvG Project</span>
        </NavLink>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
          <NavLink
            target="_blank"
            to={'https://vk.com/takeyours.online'}
            className="mr-5 hover:text-gray-900"
          >
            Вконтакте
          </NavLink>
          <NavLink
            target="_blank"
            to={'https://t.me/DvG_Project_bot'}
            className="mr-5 hover:text-gray-900"
          >
            Телеграм
          </NavLink>
          <NavLink
            target="_blank"
            to={'https://dzen.ru/takeyours'}
            className="mr-5 hover:text-gray-900"
          >
            Дзен
          </NavLink>
          <NavLink
            target="_blank"
            to={'https://www.youtube.com/@takeyours5982'}
            className="mr-5 hover:text-gray-900"
          >
            Youtube
          </NavLink>
          <NavLink
            target="_blank"
            to={
              'https://api.whatsapp.com/send/?phone=79205778788&text&type=phone_number&app_absent=0'
            }
            className="mr-5 hover:text-gray-900"
          >
            WhatsApp
          </NavLink>
        </nav>
        <div className='flex gap-2 items-center'>
          <a>+7 (499) 380-78-99</a>
          <button
            onClick={handleOpenModal}
            className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"
          >
            Заказать звонок
            <img
              src={phoneImg}
              alt="we_call"
              className="w-5 ml-2 mt-[1px] h-5"
            />
          </button>
        </div>
      </div>
      {isModalOpen && <CallNow onClose={handleCloseModal} onSubmitSuccess={handleFormSubmitSuccess} />}
      {formSubmitted && <ApplicationAcceptedModal />}
    </header>
  )
}

export default Header
