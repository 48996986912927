import { IProject } from './Project/Project.types'
import webmobilImg from "../assets/cases/webmobil.webp";
import fastomatImg from "../assets/cases/fastomat.webp";
import takeyoursImg from "../assets/cases/takeyours.webp";

export const ProjectsData: Record<string, IProject> = {
  WebMobil: {
    title: 'WebMobil',
    image: webmobilImg,
    description: 'Компания WEBMOBIL — это маркетинговое агентство, специализирующееся на оказании широкого спектра услуг в области интернет-маркетинга, рекламы и аналитики. Она предлагает комплексные решения для бизнеса, начиная от создания веб-ресурсов (сайтов, интернет-магазинов, лендингов) и заканчивая оптимизацией рекламных кампаний, аналитикой и внедрением систем автоматизации (например, CRM-системы).',
    sections: [
      {
        id: 1,
        title: 'Упаковка и реклама: ключ к успеху',
        content: 'WEBMOBIL помогает своим клиентам упаковать их торговые предложения в продающий контент, настроить рекламные кампании в различных интернет-сервисах (контекстная, медийная, социальная реклама), а также осуществлять аналитику результатов. Компания акцентирует внимание на индивидуальном подходе к каждому проекту, стремясь понимать бизнес-процессы клиента и адаптировать свои услуги под его потребности.',
      },
      {
        id: 2,
        title: 'Демонстрация опыта: кейсы проектов',
        content: 'WEBMOBIL предоставляет кейсы успешных проектов, демонстрируя свой опыт и результаты работы. Компания работает в различных отраслях, включая недвижимость, производство, торговлю, медицину, юриспруденцию, туризм, строительство, общепит, технологии, образование, финансы и автомобильный рынок. Ее методы основаны на коммуникации с клиентами на понятном им языке, оперативности и скрупулезности в работе.',
      }
    ],
  },
  Fastomat: {
    title: 'Fastomat',
    image: fastomatImg,
    description: 'На нашем сайте представлена информация о системе бесконтактной выдачи заказов - инновационном проекте, который разработан нашей компанией. Эта система позволяет клиентам оформлять заказы онлайн через мобильное приложение или веб-сайт, оплачивать их электронными средствами и забирать готовый заказ без контакта с продавцом.',
    sections: [
      {
        id: 1,
        title: 'Преимущества системы',
        content: 'Основные преимущества нашей системы включают уменьшение времени ожидания, обеспечение безопасности и удобства для клиентов. Система может быть легко интегрирована в различные сферы бизнеса, такие как фаст-фуд, аптеки, супермаркеты, банки и другие. Мы обеспечиваем мобильную интеграцию и бесконтактность процесса заказа и получения товара, что повышает эффективность обслуживания и минимизирует риски заражения вирусами.',
      },
      {
        id: 2,
        title: '',
        content: 'Более подробную информацию о нашем проекте и его преимуществах вы можете найти на нашем сайте.',
      }
    ],
  },
  TakeYours: {
    title: 'TakeYours',
    image: takeyoursImg,
    description: 'Мы разрабатываем и производим в г. Белгород системы быстрой сборки и бесконтактной выдачи заказов.',
    sections: [
      {
        id: 1,
        title: 'О проекте',
        content: 'Системы выдачи заказов TakeYours ориентированы на HoReCa - кафе, кофейни, фастфуды, пиццерии, кинотеатры, а так же любые сервисы, где может быть необходима бесконтактная выдача готовых заказов. Retail и пункты выдачи заказов интернет магазинов и маркетплейсов.',
      },
      {
        id: 2,
        title: 'TakeYours: быстрая сборка заказов',
        content: 'Системы быстрой сборки заказов TakeYours ориентированы на СКЛАДЫ, на которых осуществляется сборка заказов клиентов и требуется ускорение процессов сборки склады FMCG, канцелярских и офисных принадлежностей, маркетплейсы. Склады для B2B рынка, где можно использовать коробки для сборки, например, оптовые склады, в том числе фармацевтические. Другие склады, где сборка в коробки не подходит, но требуется навигация по складу для быстрого поиска товаров, например хлебозаводы',
      }
    ],
  },
}
