import { FC } from "react";
import dvgprojectLogo from "../../assets/dvgglogo.webp";
const Logotype: FC = () => {
  return <img 
  className="w-12 h-12"
  src={dvgprojectLogo} 
  alt="logotype" />;
};

export default Logotype;
