import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import ModalStore from '../../../store/Modals/Modal.store';
import { handleSubmit } from '../../../api/api';
import useForm from '../../../hooks/useForm';

interface CallNowProps {
  onClose: () => void;
  onSubmitSuccess: () => void;
}

const CallNow: FC<CallNowProps> = ({ onClose, onSubmitSuccess }) => {
  const { formState, loading, errors, handleChange, handleFormSubmit } = useForm({
    initialState: { name: '', phone: '' },
    handleSubmit,
    onSubmitSuccess,
  });

  const { name, phone } = formState;

  return (
    <div>
      {ModalStore.get() && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-8 rounded shadow-md">
            <button
              onClick={onClose}
              className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 font-bold text-xl focus:outline-none p-4"
            >
              х
            </button>
            <h2 className="text-xl font-semibold mb-4">Заказать звонок</h2>
            <form onSubmit={handleFormSubmit}>
              <label className="block mb-4">
                Ваше имя:
                <input
                  name="name"
                  className="w-full border rounded px-3 py-2 mt-2"
                  type="text"
                  value={name}
                  onChange={handleChange}
                  required
                />
              </label>
              {errors.name && (
                <p className="text-red-600 text-xs break-words max-w-[320px] mt-[-16px]">
                  {errors.name}
                </p>
              )}
              <label className="block mb-4">
                Номер телефона:
                <input
                  name="phone"
                  className="w-full border rounded px-3 py-2 mt-2"
                  type="tel"
                  value={phone}
                  onChange={handleChange}
                  required
                />
              </label>
              {errors.phone && (
                <p className="text-red-600 text-xs break-words max-w-[320px] mt-[-16px]">
                  {errors.phone}
                </p>
              )}
              <button
                className="bg-[#393185] text-white px-4 py-2 rounded hover:bg-purple-600"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Отправка...' : 'Заказать звонок'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(CallNow);