import { FC } from "react";
import MapContact from "../components/screens/MapContact";
import StartSection from "../components/screens/StartSection";
import OurProjects from "../components/screens/OurProjects";
import TechList from "../components/screens/TechList";
import PortfolioList from "../components/screens/PortfolioList";
import StepsList from "../components/screens/StepsList";

const MainPage: FC = () => {
  return (
    <div className="container mx-auto">
      <StartSection />
      <OurProjects />
      <TechList />
      <StepsList />
      <PortfolioList />
      <MapContact />
    </div>
  );
};

export default MainPage;