import { makeAutoObservable } from 'mobx'
import { Modal } from './Modal.types'

class ModalStore {
  private isModalOpen: Modal | null = null

  open(modalType: Modal) {
    this.isModalOpen = modalType
  }

  close() {
    this.isModalOpen = null
  }

  get() {
    return this.isModalOpen
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export default new ModalStore()
