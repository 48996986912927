import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { FetchStages } from '../../types/Fetch.types'
import My500Component from '../screens/500'
import { Spin } from 'antd'

interface ILoaderStatesProps {
  error?: ReactNode
  loader?: ReactNode
  children?: ReactNode
  state: FetchStages
}

const LoaderStatesHandler: FC<ILoaderStatesProps> = ({
  error = <My500Component />,
  loader = <Spin size="large" />,
  children = <></>,
  state,
}) => {
  switch (state) {
    case FetchStages.waiting:
      return <>{children}</>
    case FetchStages.start:
      return <>{loader}</>
    case FetchStages.error:
      return <>{error}</>
    case FetchStages.end:
      return <>{children}</>
  }
}

export default observer(LoaderStatesHandler)
