import { useState } from 'react';

interface FormState {
  [key: string]: any;
}

interface UseFormProps {
  initialState: FormState;
  handleSubmit: (
    data: FormState,
    onSuccess: () => void,
    setLoading: (loading: boolean) => void,
    setError: (error: string) => void
  ) => Promise<void>;
  onSubmitSuccess?: () => void;
}

const useForm = ({ initialState, handleSubmit, onSubmitSuccess }: UseFormProps) => {
  const [formState, setFormState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validate = (name: string, value: string) => {
    let error = '';

    if (name === 'name' && (value.length < 2 || /\d/.test(value))) {
      error = 'Имя должно быть не менее 2 букв и не содержать цифр';
    }

    if (name === 'phone' && (!/^\d+$/.test(value) || value.length < 11)) {
      error = 'Телефон содержит только цифры, не менее 11 символов';
    }

    if (name === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      error = 'Введите действительный email адрес';
    }

    return error;
  };

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const error = validate(name, value);
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const error = validate(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleError = (error: string) => {
    setLoading(false);
    setErrors({ '': error });
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setTimeout(() => {
      setLoading(true);
    }, 0);
    try {
      await handleSubmit(
        formState,
        () => {
          setLoading(false);
          onSubmitSuccess?.();
          resetForm();
          setFormSubmitted(true);
        },
        setLoading,
        (error) => {
          handleError(error);
        }
      );
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        setErrors({ '': err.message });
      } else {
        setErrors({ '': 'Неизвестная ошибка' });
      }
    }
  };
  

  const resetForm = () => {
    setFormState(initialState);
  };

  return {
    formState,
    loading,
    errors,
    handleChange,
    handleBlur,
    handleFormSubmit,
    resetForm,
    formSubmitted,
    setFormSubmitted,
  };
};

export default useForm;