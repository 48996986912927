import { FC } from "react";
import webmobilImg from "../../assets/cases/webmobil.webp";
import fastomatImg from "../../assets/cases/fastomat.webp";
import takeyoursImg from "../../assets/cases/takeyours.webp";
import { NavLink } from 'react-router-dom';

const OurProjects: FC = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col">
          <div className="h-1 bg-gray-200 rounded overflow-hidden">
            <div className="w-24 h-full bg-purple-500"></div>
          </div>
          <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
            <h2
              data-aos="zoom-right"
              data-aos-duration="1500"
              className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0"
            >
              Наши проекты
            </h2>
            <h5
              data-aos="zoom-left"
              data-aos-duration="1500"
              className="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0"
            >
              Доверьтесь нашему опыту и экспертизе, чтобы
              достичь новых высот и обеспечить успех вашего
              бизнеса. Присоединяйтесь к нашему растущему
              списку успешных проектов и начните свое
              путешествие к инновациям с нами!
            </h5>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          <div
            data-aos="fade-right"
            data-aos-duration="1500"
            className="p-4 md:w-1/3 sm:mb-0 mb-6"
          >
            <div className="rounded-lg h-64 overflow-hidden">
              <a href="https://webmobil-agency.ru/" rel="noreferrer" target="_blank">
                <img
                  loading="lazy"
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src={webmobilImg}
                />
              </a>
            </div>
            <a href="https://webmobil-agency.ru/" rel="noreferrer" target="_blank"><h3 className="text-xl font-medium title-font text-gray-900 mt-5">
              WebMobil
            </h3></a>
            <h5 className="text-base leading-relaxed mt-2">
              WEBMOBIL - агентство, объединяющее компетенции
              веб-разработки, рекламы и бизнес-аналитики.
              Выполняем проектные задачи по разработке
              эффективных моделей рекламы в интернете.
            </h5>
            <NavLink to="/projects/WebMobil" target="_blank" className="text-purple-500 inline-flex items-center mt-3">
              Подробнее
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
            </NavLink>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            className="p-4 md:w-1/3 sm:mb-0 mb-6"
          >
            <div className="rounded-lg h-64 overflow-hidden">
              <a href="https://fastomat.com/" rel="noreferrer" target="_blank">
                <img
                  loading="lazy"
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src={fastomatImg}
                />
              </a>
            </div>
            <a href="https://fastomat.com/" rel="noreferrer" target="_blank"><h3 className="text-xl font-medium title-font text-gray-900 mt-5">
              Fastomat
            </h3></a>
            <h5 className="text-base leading-relaxed mt-2">
              Фастомат - это инновация на рынке технических
              решений для HoReCa и Retail, система,
              предназначенная для автоматизации
              бесконтактной выдачи готовых заказов
            </h5>
            <NavLink to="/projects/Fastomat" target="_blank" className="text-purple-500 inline-flex items-center mt-3">
              Подробнее
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
            </NavLink>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            className="p-4 md:w-1/3 sm:mb-0 mb-6"
          >
            <div className="rounded-lg h-64 overflow-hidden">
              <a href="https://www.take-yours.online/" rel="noreferrer" target="_blank">
                <img
                  loading="lazy"
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src={takeyoursImg}
                />
              </a>
            </div>
            <a href="https://www.take-yours.online/" rel="noreferrer" target="_blank"><h3 className="text-xl font-medium title-font text-gray-900 mt-5">
              TakeYours
            </h3></a>
            <h5 className="text-base leading-relaxed mt-2">
              Наша компания разрабатывает и внедряет системы
              ультрабыстрой сборки заказов на складах FMCG и
              B2B сферы - везде, где требуется быстро
              обслуживать большой поток клиентский заявок,
              собирать множество позиций в коробки и
              передавать их на доставку.
            </h5>
            <NavLink to="/projects/TakeYours" target="_blank" className="text-purple-500 inline-flex items-center mt-3">
              Подробнее
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProjects;
