import arduinoImg from "../../assets/techs/arduino-svgrepo-com.svg";
import cplusplusImg from "../../assets/techs/c-plus-plus-svgrepo-com.svg";
import djangoImg from "../../assets/techs/django-svgrepo-com.png";
import graphImg from "../../assets/techs/graphql-svgrepo-com.svg";
import laravelImg from "../../assets/techs/laravel-svgrepo-com.svg";
import reactImg from "../../assets/techs/react-svgrepo-com.svg";
import swiftImg from "../../assets/techs/swift-svgrepo-com.svg";
import tensorflowImg from "../../assets/techs/tensorflow-svgrepo-com.svg";
import linuxImg from "../../assets/techs/linux-tux-svgrepo-com.svg";
import nextjsImg from "../../assets/techs/nextjs-icon-svgrepo-com.svg";
import nestjsImg from "../../assets/techs/nestjs-svgrepo-com.svg";
import vueImg from "../../assets/techs/vue-svgrepo-com.svg";

const TechList = () => {
  return (
    <section className="border-t border-b">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16">
        <h2 className="mb-6 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 lg:text-center dark:text-white md:text-4xl">
          Технологии которые мы используем
        </h2>
        <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl lg:px-64 lg:mb-16">
          Мы стремимся к инновациям и постоянно обновляемся,
          чтобы удовлетворить все ваши потребности и
          требования в быстро меняющихся технологических
          условиях. Доверьтесь нам и привнесите в свой
          бизнес мощь новейших технологий!
        </p>
        <div className="space-y-4 sm:grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-4 xl:gap-8 sm:space-y-0 md:mt-12">
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://react.dev/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={reactImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              React
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://www.djangoproject.com/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={djangoImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              Django
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://laravel.com/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={laravelImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              Laravel
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://www.swift.org/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={swiftImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              Swift
            </h3>
          </a>

          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://graphql.org/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={graphImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              GraphQL
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://nextjs.org/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={nextjsImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              NextJS
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://nestjs.com/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={nestjsImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              NestJS
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://vuejs.org/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={vueImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              Vue
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://learn.microsoft.com/ru-ru/cpp/?view=msvc-170"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={cplusplusImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              C++
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://www.tensorflow.org/?hl=ru"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={tensorflowImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              TensorFlow
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://www.arduino.cc/"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={arduinoImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              Arduino
            </h3>
          </a>
          <a
            data-aos="zoom-in"
            target="_blank" rel="noopener noreferrer"
            data-aos-duration="1500"
            href="https://ru.wikipedia.org/wiki/Системный_администратор"
            className="block px-8 py-12 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600 hover:shadow-lg dark:hover:shadow-lg-light"
          >
            <img
              loading="lazy"
              className="w-12 h-12 mx-auto text-blue-500"
              src={linuxImg}
              alt=""
            />
            <h3 className="font-semibold text-xl text-gray-900 dark:text-white mt-3.5">
              Администрирование
            </h3>
          </a>
        </div>
      </div>
    </section>
  );
};

export default TechList;
