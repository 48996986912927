import axios from 'axios'
import LeadData from '../interfaces/LeadData'

export const createLead = async (leadData: LeadData): Promise<any> => {
  try {
    const response = await axios.post(
      'https://dvg-project.com/api/leadAdd.php',
      leadData
    )
    return response.data
  } catch (error) {
    throw new Error('Ошибка при отправке запроса')
  }
}
