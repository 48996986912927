import { useState } from 'react';
import ModalStore from '../store/Modals/Modal.store';
import { Modal } from '../store/Modals/Modal.types';

const useModalForm = (modalKey: Modal) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => {
    ModalStore.open(modalKey);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    ModalStore.close();
    setIsModalOpen(false);
  };

  const handleFormSubmitSuccess = () => {
    setFormSubmitted(true);
    setLoading(false);
    handleCloseModal();
  };

  return {
    isModalOpen,
    formSubmitted,
    loading,
    handleOpenModal,
    handleCloseModal,
    handleFormSubmitSuccess,
  };
};

export default useModalForm;