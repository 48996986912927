import { FC, useState, useEffect } from 'react';

const ApplicationAcceptedModal: FC = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
    const timeout = setTimeout(() => {
      setShowModal(false);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {showModal && (
        <div className="fixed inset-0 z-40 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white z-50 p-8 rounded shadow-md max-w-md">
            <h2 className="text-xl font-semibold mb-4">Ваша заявка принята</h2>
            <p className="text-gray-800">Мы свяжемся с вами!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationAcceptedModal;
